/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Photos.js
// Fotos Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 06.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  Button,
} from "react-bootstrap";
import axios from "axios";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";

import moment from 'moment';

import { LightboxKey, Team } from "../env.js";

import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/";
import "../css/imageCarousel.scss";
import "lightbox.js-react/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import defaultImage from "../images/logo_CW.png";
import { Store } from "react-notifications-component";
import '@fortawesome/fontawesome-free/css/all.min.css';


// Die Funktion StallungenTable ist eine React-Komponente, die eine Tabelle für Stallungen darstellt.
// - Der aktuelle Benutzer wird aus dem lokalen Speicher (`localStorage`) abgerufen und in `currentUser` gespeichert.
// - `useState` initialisiert die folgenden Zustandsvariablen:
//   - `folk` speichert die Liste der verfügbaren Herden.
//   - `selectedFolkID` speichert die ID der aktuell ausgewählten Herde.
//   - `tableData` speichert die Daten, die in der Tabelle angezeigt werden.


function StallungenTable() {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [folk, setFolk] = useState([]);
  const [selectedFolkID, setSelectedFolkID] = useState("");
  const [tableData, setTableData] = useState([]);


// Dieser useEffect-Hook wird ausgelöst, wenn `currentUser.betriebID` sich ändert.
// - Es definiert und ruft sofort die Funktion `fetchFolk` auf, um die Herden-Daten zu laden.
// - `fetchFolk` sendet eine GET-Anfrage an den Endpunkt "/api/folk" mit der `betriebID` als Parameter.
// - Die empfangenen Daten werden in `folk` gespeichert, und die ID der ersten Herde wird als `selectedFolkID` gesetzt, falls Daten vorhanden sind.
// - Fehler während des Datenabrufs werden im Konsolenprotokoll ausgegeben.


  useEffect(() => {
    const fetchFolk = async () => {
      try {
        const operationID = currentUser.betriebID;
        const res = await axios.get("/api/folk", {
          params: { operationID },
        });
        setFolk(res.data);
        
        if (res.data.length > 0) {
          setSelectedFolkID(res.data[0].id.toString());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, [currentUser.betriebID]);

///TODO /selectTillCurrentDate
// In Verwendung außerhalb von Showcases sollte 'datas/' verwendet werden, da /selectTillCurrentDate nur die Daten bis zum aktuellen Datum zurück gibt
  const fetchData = async () => {
    if (!selectedFolkID) return;
    try {
      const res = await axios.get("/api/datas/selectTillCurrentDate", {
        params: { selectedFolkID },
      });

      setTableData(res.data);
    } catch (err) {
      console.log(err);
    }
  };


// Dieser useEffect-Hook wird ausgelöst, wenn `selectedFolkID` sich ändert.
// - Er ruft die Funktion `fetchData` auf, um die entsprechenden Daten basierend auf der ausgewählten Herde abzurufen.
// - `handleDropdownChange` wird verwendet, um die ausgewählte Herde zu ändern und somit `selectedFolkID` zu aktualisieren.

// Zustandsvariablen:
// - `images` speichert die Bilder, die für die Anzeige vorgesehen sind.
// - `isOpen` bestimmt, ob ein Modal-Fenster geöffnet oder geschlossen ist.
// - `currentImageIndex` speichert den Index des aktuell angezeigten Bildes im Modal.


  useEffect(() => {
    fetchData();
  }, [selectedFolkID]);

  const handleDropdownChange = (e) => {
    setSelectedFolkID(e.target.value);
  };

  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


// Dieser useEffect-Hook wird bei jedem Rendern der Komponente ausgeführt.
// - Er initialisiert die Lightbox-JavaScript-Bibliothek durch Aufruf von `initLightboxJS` mit den Parametern `LightboxKey` und `Team`.

// Die Funktion `closeLightbox` setzt den Zustand `isOpen` auf `false`, um die Lightbox zu schließen.
// `window.moment` wird auf die Moment.js-Bibliothek gesetzt und die Locale wird auf die Standard-Locale eingestellt.

// `positivOptions` definiert eine Liste von Optionen für die Auswahl zwischen "Positiv" und "Negativ".


  useEffect(() => {
    initLightboxJS(LightboxKey, Team);
  });

  

  const closeLightbox = () => {
    setIsOpen(false);
  };
  window.moment = moment;
  moment.locale();

  const positivOptions = [
    { id: 0, label: 'Positiv' },
    { id: 1, label: 'Negativ' },
  ];
  

// `columns` ist ein Array von Objekten, das die Konfiguration für die Spalten einer Tabelle definiert.
// - Jede Spalte hat Eigenschaften wie:
//   - `name`: Der Name der Spalte, der auch für die Identifizierung verwendet wird (hier "id").
//   - `header`: Die Überschrift, die in der Tabellenkopfzeile angezeigt wird (hier "ID").
//   - `minWidth`: Die minimale Breite der Spalte (hier 50 Pixel).
//   - `maxWidth`: Die maximale Breite der Spalte (hier 100 Pixel).
//   - `defaultFlex`: Die Flex-Eigenschaft der Spalte, die den verfügbaren Platz aufteilt (hier 1).
//   - `filterEditor`: Der Filtertyp, der in der Spalte verwendet wird (hier `NumberFilter`).


  const columns = [
    {
      name: "id",
      header: "ID",
      type: "number",
      minWidth: 50,
      maxWidth: 100,
      defaultFlex: 1,
      filterEditor: NumberFilter,
    },


    // Diese Spalte in der Tabelle zeigt Datums- und Uhrzeitwerte an und hat folgende Eigenschaften:
    // - `name`: 'datum'
    // - `header`: 'Datum Uhrzeit'
    // - `defaultFlex`: 1
    // - `dateFormat`: Das Format für die Anzeige des Datums und der Uhrzeit (hier 'DD.MM.YYYY HH:mm')
    // - `filterEditor`: Der Filtertyp für Datumswerte (hier `DateFilter`)
    // - `filterEditorProps`: Konfiguriert die Eigenschaften des Datumsfilters, einschließlich des Formats für die Filtereingabe und des Platzhaltertexts
    // - `render`: Formatiert die Datumswerte für die Anzeige in der Tabelle gemäß dem angegebenen Format 'DD.MM.YYYY HH:mm' mithilfe der Moment.js-Bibliothek


    {
      name: 'datum',
      header: 'Datum Uhrzeit',
      defaultFlex: 1,
      dateFormat: 'DD.MM.YYYY HH:mm', // korrektes Format hier einstellen
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'YYYY-MM-DD', // oder welches Format Sie für den Filter bevorzugen
          placeholder: index === 1 ? '' : '' // oder entsprechenden Platzhaltertext
        };
      },
      render: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm'), // Formatierung hier
    },


// Diese Spaltenkonfiguration definiert zusätzliche Spalten für eine Tabelle:
// - 'stallbezeichnung': Zeigt die Bezeichnung des Stalls an, Typ 'string', minimale Breite 150px.
// - 'token': Zeigt den Chickenwatcher-Token an, Typ 'string', minimale Breite 150px.
// - 'gewicht': Zeigt das Gewicht in Gramm an, Typ 'string', minimale Breite 150px.
// - 'helligkeit': Zeigt die Helligkeit in Lux an, minimale Breite 150px.


    {
      name: "stallbezeichnung",
      header: "Stallung",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "token",
      header: "Chickenwatcher",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "gewicht",
      header: "Gewicht in g",
      type: "number",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "helligkeit",
      header: "Helligkeit in lux",
      type: "number",
      minWidth: 150,
      defaultFlex: 1,
    },


// Diese Spalte zeigt den Befund an und hat folgende Eigenschaften:
// - `name`: 'positiv'
// - `header`: 'Befund'
// - `minWidth`: 150px
// - `defaultFlex`: 1
// - `filterEditor`: Ermöglicht das Filtern mit einer Auswahlbox (`SelectFilter`)
// - `filterEditorProps`: Konfiguriert die Filterauswahl mit Platzhalter 'Alle' und Datenquelle `positivOptions`
// - `render`: Zeigt den Wert als "Positiv" in roter Schrift oder als "Negativ" an, je nach Wert (1 oder 0)


    {
      enableColumnFilterContextMenu: false,
      name: "positiv",
      header: "Befund",
      minWidth: 150,
      defaultFlex: 1,
      filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'Alle',
          dataSource: positivOptions,
        },
        render: ({ value }) => 
          value === 1 
            ? <span style={{ color: 'red' }}>Positiv</span> 
            : value === 0 
              ? "Negativ" 
              : ""

      },


// Diese Spalte zeigt eine Tagesstatistik an und hat folgende Eigenschaften:
// - `name`: 'statistik'
// - `header`: 'Tagesstatistik'
// - `minWidth`: 50px
// - `maxWidth`: 150px
// - `render`: Zeigt unterschiedliche Schaltflächen basierend auf dem Wert von `data.statistik` an:
//   - Wert 0: Zeigt einen grünen Button mit einem Häkchen, der `handleIgnoreClick` auslöst
//   - Wert 1: Zeigt einen roten Button mit einem Kreuz, der `handleIgnoredClick` auslöst
//   - Andernfalls wird "Keine Aktion" angezeigt


      {
        name: "statistik",
        header: "Tagesstatistik",
        minWidth: 50,
        maxWidth: 150,
        render: ({ data }) => {
          if (data.statistik === 0) {
            return (
              <div>
                <Button
                  className="button-clear green"
                  onClick={() => handleIgnoreClick(data)}
                >
                  <i class="fas fa-check"></i>
                </Button>
              </div>
            );
          } else if (data.statistik === 1) {
            return (
              <div>
                <Button
                  className="button-clear red"
                  onClick={() => handleIgnoredClick(data)}
                >
                  <i class="fas fa-times"></i>
                </Button>
              </div>
            );
          } else {
            return <div>Keine Aktion</div>;
          }
        },
      }
      
  ];


// Die Funktion `handleIgnoreClick` wird aufgerufen, wenn auf den "Ignore"-Button geklickt wird.
// Sie aktualisiert den Status der Daten im Backend und behandelt Fehler wie folgt:
// - Der `dataId` und `status` (hier 1) werden an die API gesendet, um die Daten zu aktualisieren.
// - Nach erfolgreicher Aktualisierung wird `fetchData` aufgerufen, um die Tabelle neu zu laden.
// - Bei einem Fehler wird eine Benachrichtigung angezeigt, die den Benutzer über das Problem informiert.


  const handleIgnoreClick = async (rowData) => {
    const dataId = rowData.id;
    const status = 1;
  
    try {
      const response = await axios.put("/api/datas/updateData", { dataId, status });
      const updatedRow = response.data;
      
      fetchData();

    } catch (err) {
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      });
    }
  };
  

// Die Funktion `handleIgnoredClick` wird aufgerufen, wenn auf den "Ignored"-Button geklickt wird.
// Sie aktualisiert den Status der Daten im Backend und behandelt Fehler wie folgt:
// - Der `dataId` und `status` (hier 0) werden an die API gesendet, um die Daten zu aktualisieren.
// - Nach erfolgreicher Aktualisierung wird `fetchData` aufgerufen, um die Tabelle neu zu laden.
// - Bei einem Fehler wird eine Benachrichtigung angezeigt, die den Benutzer über das Problem informiert.


  const handleIgnoredClick = async (rowData) => {


    const dataId = rowData.id;
    const status = 0;
  
    try {
      await axios.put("/api/datas/updateData", { dataId, status });
      
      fetchData();

    } catch (err) {    
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      });
    }
  };


// `groups` definiert eine Gruppierungskonfiguration für eine Tabelle, in der es eine Gruppe namens "Sonstiges" gibt.
// `defaultSortInfo` legt die Standard-Sortierreihenfolge fest: Die Tabelle wird standardmäßig nach der Spalte 'datum' in absteigender Reihenfolge sortiert.



  const groups = [{ name: "other", header: "Sonstiges" }];

  const defaultSortInfo = { name: 'datum', dir: -1 }


// `filterValue` definiert eine Reihe von Filtern für eine Tabelle, um Daten basierend auf verschiedenen Kriterien zu filtern:
// - `id` filtert nach IDs größer oder gleich einem bestimmten Wert.
// - `datum` filtert nach Datumsangaben, die nach einem bestimmten Datum liegen oder an diesem Datum liegen.
// - `stallbezeichnung` filtert nach Stallbezeichnungen, die mit einem bestimmten Wert beginnen.
// - `token` filtert nach exakten Übereinstimmungen für Tokens.
// - `gewicht` filtert nach Gewichten größer oder gleich einem bestimmten Wert.
// - `helligkeit` filtert nach Helligkeiten größer oder gleich einem bestimmten Wert.
// - `positiv` filtert nach einem Auswahlwert für Befunde, der nicht gleich dem angegebenen Wert ist.


  const filterValue = [
    { name: "id", operator: "gte", type: "number" },
    {
      name: "datum",
      operator: "afterOrOn",
      type: "date",
      value: "",
      dir: -1
    },
    { name: "stallbezeichnung", operator: "startsWith", type: "string", value: "" },
    { name: "token", operator: "eq", type: "string", value: "" },
    { name: "gewicht", operator: "gte", type: "number", value: "" },
    { name: "helligkeit", operator: "gte", type: "number", value: "" },
    { name: "positiv", operator: "neq", type: "select", value: "" },
  ];


// Der `useState`-Hook initialisiert den Zustand `selected` als leeres Objekt. 
// `onSelectionChange` ist eine Callback-Funktion, die aufgerufen wird, wenn die Auswahl in der Tabelle geändert wird.
// Sie aktualisiert den Zustand `selected` mit den Daten der ersten ausgewählten Zeile.
// Die Funktion erstellt eine Liste `updatedImages` von Bildobjekten, die die URLs der Bilder aufbereitet und speichert.
// Diese Liste wird dann im Zustand `images` gespeichert.


  const [selected, setSelected] = useState({});

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    setSelected(data[0]);
    const selectedRow = data[0];

    const updatedImages = [
      {
        name: "fotoOben",
        token: selectedRow.token,
        src: selectedRow.fotoOben.split("/").pop(),
      },
      {
        name: "fotoUnten",
        token: selectedRow.token,
        src: selectedRow.fotoUnten.split("/").pop(),
      },
      {
        name: "fotoLinks",
        token: selectedRow.token,
        src: selectedRow.fotoLinks.split("/").pop(),
      },
      {
        name: "fotoRechts",
        token: selectedRow.token,
        src: selectedRow.fotoRechts.split("/").pop(),
      },
      


    ];

    setImages(updatedImages);
  }, []);


// Der `i18n`-Objekt definiert die Übersetzungen für bestimmte Texte in der ReactDataGrid-Komponente.
// Es wird durch das Mischen der Standard-Übersetzungen aus `ReactDataGrid.defaultProps.i18n` mit benutzerdefinierten Texten erstellt.
// Die benutzerdefinierten Texte übersetzen Begriffe wie "Seite", "von", "Ergebnisse pro Seite" und "Zeigt" ins Deutsche.


  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText:'Seite ',
    ofText:' von ',
    perPageText:'Ergebnisse pro Seite',
    showingText:'Zeigt '

  })


  // `selectedHerde` sucht im `folk`-Array nach dem Eintrag, dessen `id` mit `selectedFolkID` übereinstimmt.
  // Die `id` wird in einen String konvertiert, um sicherzustellen, dass der Vergleich korrekt ist.
// `gridStyle` definiert die minimale Höhe für das Grid-Layout.
  
  const selectedHerde = folk.find(herde => herde.id.toString() === selectedFolkID);

  const gridStyle = { minHeight: 550 };


// Die Komponente zeigt Details zu Herden-Daten an und ermöglicht die Auswahl einer Herde aus einem Dropdown-Menü.
// Sie besteht aus:
// - Einem Card-Header, der die aktuell ausgewählte Herde anzeigt oder eine Aufforderung zur Auswahl einer Herde,
//   sowie einem Dropdown-Menü zur Auswahl der Herde.
// - Einem DataGrid, das die Daten der ausgewählten Herde in einer Tabelle darstellt, inklusive Filter- und Sortierfunktionen.
// - Einer Slideshow-Komponente, die Fotos der ausgewählten Herde anzeigt und eine Lightbox für die Bildansicht bietet.


  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">
                  Herden Daten
                </h1>
                <Card className="shadow">
                  <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                    <p className="m-0 fw-bold">Herden Daten</p>
                    {selectedHerde ? (
                      <div>
                        <b>Rasse:</b> {selectedHerde.rassenBezeichnung } &emsp;
                        <b>Einstalldatum:</b> {selectedHerde.einstallDatum+" "} &emsp;
                        <b>Ausstalldatum:</b> {selectedHerde.ausstallDatum} 
                      </div>
                    ) : (
                      <div>Herde auswählen</div>
                    )}
        

                    <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkID}
                    onChange={handleDropdownChange}
                    required
                  >
                    {folk.map((herde) => (
                      <option key={herde.id} value={herde.id}>
                        {herde.bezeichnung}
                      </option>
                    ))}
                  </select>
                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <ReactDataGrid
                        idProperty="organisatorID"
                        defaultFilterValue={filterValue}
                        columns={columns}
                        groups={groups}
                        editable={false}
                        dataSource={tableData}
                        style={gridStyle}
                        defaultLimit={10}
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                        pagination
                        i18n={i18n}
                        defaultSortInfo={defaultSortInfo}
  
                      />
                    </Row>

                    <Row>
                      {/* Lightbox */}
                      <div className="divSlideshowLightbox">
                        <h1 className="title">Fotos</h1>
                        <SlideshowLightbox
                          key={images.length}
                          className=""
                          showThumbnails={true}
                          images={images.map((image, index) => {
                            return {
                              src: `/api/photos/${image.token}/${image.src}`,
        
                            };
                          })}
                          isOpen={isOpen}
                          onClose={closeLightbox}
                          currentIndex={currentImageIndex}
                        />
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default StallungenTable;
