/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Netbee.js
// Page für weitere Umgebungsdaten
//
// Author:          Florian Karner
// Erstellt am:     28.10.2024
// Letzte Änderung: 05.11.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, Spinner, Table, Form } from "react-bootstrap";
import axios from "axios";
import "../css/main.scss";

const Netbee = () => {
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [tempSortOrder, setTempSortOrder] = useState("");

  const fetchNetbeeData = async () => {
    try {
      const response = await axios.get("/api/netbee/three-days");
      if (response.data) {
        setDisplayedData(response.data);
      }
    } catch (error) {
      console.error("Error fetching NetBEE data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNetbeeData();
  }, []);

  const handleSort = useCallback(() => {
    if (!dateSortOrder && !tempSortOrder) {
      fetchNetbeeData();
      return;
    }

    let sortedData = [...displayedData];

    if (dateSortOrder && tempSortOrder) {
      sortedData.sort((a, b) => {
        const dateDiff =
          dateSortOrder === "asc"
            ? new Date(a.timestamp) - new Date(b.timestamp)
            : new Date(b.timestamp) - new Date(a.timestamp);

        if (dateDiff === 0) {
          return tempSortOrder === "asc"
            ? a.temperature - b.temperature
            : b.temperature - a.temperature;
        }
        return dateDiff;
      });
    } else if (dateSortOrder) {
      sortedData.sort((a, b) =>
        dateSortOrder === "asc"
          ? new Date(a.timestamp) - new Date(b.timestamp)
          : new Date(b.timestamp) - new Date(a.timestamp)
      );
    } else if (tempSortOrder) {
      sortedData.sort((a, b) =>
        tempSortOrder === "asc" ? a.temperature - b.temperature : b.temperature - a.temperature
      );
    }

    setDisplayedData(sortedData);
  }, [dateSortOrder, tempSortOrder, displayedData]);

  useEffect(() => {
    handleSort();
  }, [dateSortOrder, tempSortOrder, handleSort]);

  return (
    <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="main">
        <Row className="justify-content-center">
          <Col md={12}>
            <Card className="shadow mb-3" style={{ marginTop: '20px', minWidth: '1200px' }}>
              <Card.Header className="py-3 text-center">
                <h2 className="m-0" style={{ fontSize: '1.5rem', color: '#e36c30' }}>
                  Umgebungsdaten der letzten 3 Tage
                </h2>
              </Card.Header>
              <Card.Body>
                <Form className="mb-3 d-flex justify-content-between">
                  <Form.Group controlId="dateSort" className="me-3">
                    <Form.Label>Datum Sortieren</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setDateSortOrder(e.target.value);
                        setTempSortOrder("");
                      }}
                      aria-label="Date Sort Order"
                      value={dateSortOrder}
                    >
                      <option value="">Sortieren...</option>
                      <option value="asc">Älteste zuerst</option>
                      <option value="desc">Neueste zuerst</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group controlId="tempSort" className="me-3">
                    <Form.Label>Temperatur Sortieren</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setTempSortOrder(e.target.value);
                        setDateSortOrder("");
                      }}
                      aria-label="Temperature Sort Order"
                      value={tempSortOrder}
                    >
                      <option value="">Sortieren...</option>
                      <option value="asc">Niedrigste zuerst</option>
                      <option value="desc">Höchste zuerst</option>
                    </Form.Select>
                  </Form.Group>
                </Form>

                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : displayedData.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Datum und Zeit</th>
                        <th>Temperatur (°C)</th>
                        <th>Luftdruck (hPa)</th>
                        <th>Luftfeuchtigkeit (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedData.map((item, index) => (
                        <tr key={index}>
                          <td>{new Date(item.timestamp).toLocaleString('de-DE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                          })}</td>
                          <td>{item.temperature}</td>
                          <td>{item.airPressure}</td>
                          <td>{item.humidity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>Keine Umgebungsdaten verfügbar.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Netbee;

