/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// App.js
// Hauptdatei der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////


import React from "react";
import logo from "./logo.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";




import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './css/login.scss';
import './css/main.scss';


import Home from "./pages/Home";
import Login from "./pages/Login";



import NavSidebar from './components/Sidebar.jsx';
import Navbar from "./components/Navbar.jsx";
import { useNavigate } from "react-router-dom";
import User from "./pages/User";
import Operation from "./pages/Operation";
import NewFolk from "./pages/NewFlock";
import NewStable from "./pages/NewStable";
import History from "./pages/History";
import Photos from "./pages/Photos";
import Allocation from "./pages/Allocation";
import { useContext } from "react";
import { AuthContext } from "./context/authContext";
import PieChartComponent from "./components/PieChartComponent";
import Netbee from "./pages/Netbee";



const Layout = () => {

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

    return (
      <div>
        {currentUser ? (
          <div className="sidebarbackground" 
            style={{ 
              display: 'flex',
              height: '100%',
              // backgroundColor: '#d06b17' 
            }}>
            {/* Sidebar - Nehmen wir an, sie hat eine dynamische Breite */}
            <div id="wrapper" style={{ flexShrink: 0 }}>
              <NavSidebar />
            </div>
  
            {/* Hauptinhalt Container, der Navbar und Hauptinhalt umfasst */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              {/* Navbar oben mit 100% Breite des verfügbaren Raums */}
              <div style={{ width: '100%' }}>
                <Navbar />
              </div>
  
              {/* Hauptinhalt unter der Navbar */}
              <div id="content-wrapper" style={{ flex: 1 }}>
                <Outlet />
              </div>
            </div>
          </div>
        ) : (
          <Login />
        )}
      </div>
    );
  
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/operation",
        element: <Operation />,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/newstable",
        element: <NewStable />,
      },
      {
        path: "/newfolk",
        element: <NewFolk />,
      },
      {
        path: "/allocation",
        element: <Allocation />,
      },
      {
        path: "/overview",
        element: <NewFolk />,
      },
      {
        path: "/photos",
        element: <Photos />,
      },
      {
        path: "/piechartcomponent",
        element: <PieChartComponent />,
      },
      {
        path: "/netbee",
        element: <Netbee />
      }
   
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {


  return (

    <div className="app">
      <div>
        <RouterProvider router={router} />
      </div>
    </div>

  );
}

export default App;
