/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Login.js
// Hauptdatei der Anwendung
//
// Author:          Julia Cossee
// Erstellt am:     09.03.2023
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import axios from "axios";
import { useContext } from "react";
import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

import Logo from "../images/chiwa_logo_colored.svg";

import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "bootstrap";

import "../css/login.scss";


// Komponente für die Login-Seite.
// 1. **State-Verwaltung**:
//    - `inputs`: Speichert die Eingabewerte für Benutzername und Passwort.
//    - `err`: Speichert etwaige Fehlernachrichten während der Anmeldung.
// 2. **Hooks**:
//    - `navigate`: Funktion zum Navigieren zu anderen Routen nach erfolgreichem Login.
//    - `login`: Funktion aus dem `AuthContext` zum Einloggen des Benutzers.
//    - `currentUser`: Benutzerinformationen aus dem `AuthContext` (wird hier nicht verwendet).
// 3. **Event-Handler**:
//    - `handleChange`: Aktualisiert den `inputs`-State bei Eingaben in die Formularfelder.
//    - `handleSubmit`: Führt die Login-Logik aus, zeigt Fehler an und navigiert nach erfolgreichem Login zur Startseite.


const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setError] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const navigate = useNavigate();

  const { login, currentUser } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      navigate("/");
    } catch (err) {
      if (err.response.status === 404) {
        setUsernameError("Benutzername nicht gefunden");
      } else if (err.response.status === 400) {
        setPasswordError("Falsches Passwort");
      } else {
        setError(err.response.data);
      }
    }
  };

  const handleFocus = (field) => {
    if (field === "username") {
      setUsernameError(null);
    } else if (field === "password") {
      setPasswordError(null);
    }
  };


// Login-Komponente zur Benutzeranmeldung
// 1. **Layout**:
//    - `Container`: Hält den gesamten Inhalt innerhalb eines responsiven Containers.
//    - `Row` und `Col`: Strukturieren den Inhalt in Zeilen und Spalten für responsives Design.
// 2. **Inhalte**:
//    - `img`: Zeigt das Logo an.
//    - `h1`: Begrüßt die Benutzer mit einer Willkommensnachricht.
//    - `Form`: Beinhaltet die Anmeldefelder und den Anmelde-Button.
// 3. **Formularfelder**:
//    - `input` (Benutzername): Erforderliches Textfeld für den Benutzernamen, aktualisiert `inputs.username` bei Änderungen.
//    - `input` (Passwort): Erforderliches Passwortfeld, aktualisiert `inputs.password` bei Änderungen.
//    - `Button`: Sendet das Formular zur Anmeldung bei Klick.
// 4. **Formularverhalten**:
//    - `onSubmit={handleSubmit}`: Ruft die `handleSubmit`-Funktion auf, um das Login zu verarbeiten und den Benutzer weiterzuleiten.


return (
  <div id="logincontainer">
    <Container fluid>
      <Row>
        <Col>
          <img className="loginlogo" src={Logo} alt="Logo" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Willkommen beim ChickenWatcher</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <h5>Anmeldung</h5>
            <input
              required
              type="text"
              className="form-control"
              placeholder="Benutzername"
              name="username"
              onChange={handleChange}
              onFocus={() => handleFocus("username")} // Lösche Fehler beim Fokussieren
            />
            {usernameError && (
              <div style={{ color: "red" }}>{usernameError}</div>
            )}

            <input
              required
              className="form-control"
              type="password"
              placeholder="Passwort"
              name="password"
              onChange={handleChange}
              onFocus={() => handleFocus("password")} // Lösche Fehler beim Fokussieren
            />
            {passwordError && (
              <div style={{ color: "red" }}>{passwordError}</div>
            )}

            <Button type="submit" variant="primary">
              Anmelden
            </Button>
          </Form>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  </div>
);
};

export default Login;
