/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Sidebar.js
// Hauptdatei der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 10.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////


import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { PieChart, JournalBookmark, Archive, Folder, BoxArrowInLeft, List, ThermometerHigh } from "react-bootstrap-icons";
import Logo from "../images/chiwa_logo_colored_grey.svg";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Container, Row, Col } from "react-bootstrap";
import "../css/sidebar.scss";
import { useNavigate } from "react-router-dom";

const NavSidebar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Funktion welche die collapsing Sidebar kontrolliert
  const collapseSidebarDef = () => {
    setIsCollapsed(!isCollapsed);
    let picture = document.getElementById("sidebarlogo");
    if (isCollapsed) {
      picture.style.visibility = "visible";
    } else {
      picture.style.visibility = "hidden";
    }
  };

  return (
    <div className="sidebarbox" style={{ display: "flex", height: "100vh" }}>
      <Sidebar
        id="sidebar" backgroundColor= "none" style={{ height: "100%",backgroundColor: "none" }}
        collapsed={isCollapsed}
      >
        <Container fluid className="sidebarheader">
          <Row className="sidebarrow">
            <Col xs={7}>
              <h1>
                {!isCollapsed && <p>Hallo, {currentUser?.vorname}</p>}
              </h1>
            </Col>
            <Col></Col>
            <Col>
              <List onClick={collapseSidebarDef} className="toggle-icon" />
            </Col>
          </Row>
          <Row className="sidebarrow">
            <Link to="/">
              <img id="sidebarlogo" className="logo" src={Logo} alt="logo" />
            </Link>
          </Row>
        </Container>
        <Menu>
          <MenuItem
            component={<Link to="/" />}
            icon={<PieChart />}
            className="menu-item"
          >
            Dashboard
          </MenuItem>

          <SubMenu label="Administration" icon={<JournalBookmark />} className="submenu-item">
            <MenuItem component={<Link to="/user" />}>Benutzer</MenuItem>
            <MenuItem component={<Link to="/operation" />}>Betrieb</MenuItem>
          </SubMenu>

          <SubMenu label="Stammdaten" icon={<Archive />} className="submenu-item">
            <MenuItem component={<Link to="/history" />}>Historie Herden</MenuItem>
            <MenuItem component={<Link to="/newstable" />}>Stallung</MenuItem>
            <MenuItem component={<Link to="/newfolk" />}>Neue Herde</MenuItem>
            <MenuItem component={<Link to="/allocation" />}>Zuweisung</MenuItem>
          </SubMenu>

          <MenuItem component={<Link to="/photos" />} icon={<Folder />} className="menu-item">
            Daten
          </MenuItem>

          <MenuItem component={<Link to ="/netbee" />} icon={<ThermometerHigh />} className="menu-item">
            Umgebungsdaten
          </MenuItem>

          {currentUser ? (
            <MenuItem onClick={logout} className="logout menu-item" icon={<BoxArrowInLeft />}>
              Logout
            </MenuItem>
          ) : (
            navigate("/login")
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default NavSidebar;

