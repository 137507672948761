/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Photos.js
// Fotos Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  Button,
} from "react-bootstrap";
import axios from "axios";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from 'moment';
import { LightboxKey, Team } from "../env.js";

import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/";
import "../css/imageCarousel.scss";
import "lightbox.js-react/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import defaultImage from "../images/logo_CW.png";
import { Store } from "react-notifications-component";
import '@fortawesome/fontawesome-free/css/all.min.css';


// History-Komponente zur Verwaltung und Anzeige der historischen Daten
// 1. **Zustand**:
//    - `folk`: Speichert die Daten zu Herden.
//    - `selectedFolkID`: Hält die ID der aktuell ausgewählten Herde.
//    - `tableData`: Speichert die Daten, die in der Tabelle angezeigt werden sollen.
// 2. **Datenquelle**:
//    - `currentUser` wird aus dem `localStorage` geladen, um Informationen über den aktuellen Benutzer zu erhalten.
// 3. **Debugging**:
//    - `console.log(folk)`: Gibt die Daten der Herden im Konsolen-Log aus, um die Daten zu überwachen.


function History() {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [folk, setFolk] = useState([]);
  const [selectedFolkID, setSelectedFolkID] = useState("");
  const [tableData, setTableData] = useState([]);
  console.log(folk);


// useEffect-Hook zum Abrufen der Herden-Daten und initialen Auswahl
// 1. **Effekt**:
//    - Lädt die Herden-Daten von der API bei der ersten Renderung und bei Änderungen der `betriebID` des aktuellen Benutzers.
// 2. **Datenabruf**:
//    - `fetchFolk` sendet eine GET-Anfrage an die API, um alle Herden für den aktuellen Betrieb zu erhalten.
// 3. **Zustandsaktualisierung**:
//    - `setFolk(res.data)` speichert die abgerufenen Daten in `folk`.
//    - Wenn Herden-Daten vorhanden sind, wird die erste Herde als ausgewählt (`setSelectedFolkID`).
// 4. **Fehlerbehandlung**:
//    - Fehler bei der API-Anfrage werden in der Konsole protokolliert.


  useEffect(() => {
    const fetchFolk = async () => {
      try {
        const operationID = currentUser.betriebID;
        const res = await axios.get("/api/folk/selectAllFlock/", {
          params: { operationID },
        });
        setFolk(res.data);
        
        if (res.data.length > 0) {
          setSelectedFolkID(res.data[0].id.toString());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, [currentUser.betriebID]);


// Funktion zum Abrufen von Daten basierend auf der ausgewählten Herde
// 1. **Datenabruf**:
//    - `fetchData` wird nur ausgeführt, wenn `selectedFolkID` gesetzt ist.
//    - Eine GET-Anfrage wird an die API gesendet, um Daten für die ausgewählte Herde abzurufen.
// 2. **Zustandsaktualisierung**:
//    - Die erhaltenen Daten werden in `tableData` gespeichert.
// 3. **Fehlerbehandlung**:
//    - Fehler bei der API-Anfrage werden in der Konsole protokolliert.


  const fetchData = async () => {
    if (!selectedFolkID) return;
    try {
      const res = await axios.get("/api/datas", {
        params: { selectedFolkID },
      });

      setTableData(res.data);
    } catch (err) {
      console.log(err);
    }
  };


// useEffect zum Abrufen von Daten bei Änderung der ausgewählten Herde
// 1. **Datenabruf**:
//    - `fetchData` wird aufgerufen, wenn sich `selectedFolkID` ändert, um aktuelle Daten abzurufen.

// Event-Handler für Dropdown-Änderungen
// 1. **Dropdown-Änderung**:
//    - `handleDropdownChange` aktualisiert `selectedFolkID` basierend auf der Auswahl im Dropdown-Menü.

// Zustand für Bilder und Bildanzeige
// 1. **Bilder-Zustand**:
//    - `images` speichert die geladenen Bilder.
// 2. **Bildanzeige-Zustand**:
//    - `isOpen` steuert, ob die Bildanzeige geöffnet ist oder nicht.
//    - `currentImageIndex` speichert den Index des aktuell angezeigten Bildes.


  useEffect(() => {
    fetchData();
  }, [selectedFolkID]);

  const handleDropdownChange = (e) => {
    setSelectedFolkID(e.target.value);
  };

  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  // Moment.js Konfiguration
// 1. **Globale Moment.js-Instanz**:
//    - `window.moment = moment;` setzt Moment.js als globale Variable `moment`.
// 2. **Moment.js-Spracheinstellungen**:
//    - `moment.locale();` gibt die aktuell eingestellte Locale von Moment.js zurück. 
//      Falls keine spezifische Locale gesetzt ist, wird die Standardlocale verwendet.


  window.moment = moment;
  moment.locale();


// Optionen für eine Auswahl zwischen zwei Zuständen
// - `positivOptions` enthält zwei Optionen:
//   1. **Negativ**: Option mit ID 1
//   2. **Positiv**: Option mit ID 2

  
  const positivOptions = [
    { id: 1, label: 'Negativ' },
    { id: 2, label: 'Positiv' },
  ];


// Definiert die Spaltenkonfiguration für eine Tabelle, einschließlich der Filter- und Formatierungsoptionen.
// - Die Spalte "id" zeigt numerische IDs an und verwendet einen Filter für numerische Werte.
// - Die Spalte "bezeichnung" zeigt die Herden-Bezeichnung an und verwendet keine spezifischen Filter oder Formatierungen.
// - Die Spalte "einstalldatum" zeigt das Installationsdatum im Format 'DD.MM.YYYY' an und verwendet einen Datum-Filter.
// - Die Spalte "ausstalldatum" zeigt das Ausstallungsdatum im Format 'DD.MM.YYYY' an und verwendet einen Datum-Filter.
// - Die Spalte "rassenBezeichnung" zeigt die Rasse an und verwendet keine spezifischen Filter oder Formatierungen.
// - Die Spalte "aktiv" zeigt den Aktivitätsstatus an, verwendet einen Select-Filter zur Auswahl von "Positiv" oder "Negativ" und formatiert die Anzeige entsprechend.


  const columns = [
    {
      name: "id",
      header: "ID",
      minWidth: 50,
      maxWidth: 100,
      defaultFlex: 1,
      filterEditor: NumberFilter,
    },
    {
        name: "bezeichnung",
        header: "Herden-Bezeichnung",
        type: "string",
        minWidth: 150,
        defaultFlex: 1,
    },
    {
      name: 'einstalldatum',
      header: 'Einstalldatum',
      defaultFlex: 1,
      dateFormat: 'DD.MM.YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'YYYY-MM-DD', 
          placeholder: index === 1 ? '' : ''
        };
      },
      render: ({ value }) => moment(value).format('DD.MM.YYYY'),
    },
    {
        name: 'ausstalldatum',
        header: 'Ausstalldatum',
        defaultFlex: 1,
        dateFormat: 'DD.MM.YYYY',
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return {
            dateFormat: 'YYYY-MM-DD',
            placeholder: index === 1 ? '' : ''
          };
        },
        render: ({ value }) => moment(value).format('DD.MM.YYYY'),
    },
    {
      name: "rassenBezeichnung",
      header: "Rasse",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
        enableColumnFilterContextMenu: false,
        name: "aktiv",
        header: "Aktiv",
        minWidth: 150,
        defaultFlex: 1,
        filterEditor: SelectFilter,
          filterEditorProps: {
            placeholder: 'Alle',
            dataSource: positivOptions,
          },
          render: ({ value }) => 
            value === 1 
              ? <span style={{ color: 'red' }}>Positiv</span> 
              : value === 0 
                ? "Negativ" 
                : ""
  
        },



     
      
  ];


// Definiert die Gruppierung und Sortierung für die Tabelle:
// - `groups` enthält eine Gruppe mit dem Namen "other" und der Überschrift "Sonstiges".
// - `defaultSortInfo` legt die Standardsortierung auf die Spalte "datum" im absteigenden (`-1`) Sortiermodus fest.


  const groups = [{ name: "other", header: "Sonstiges" }];

  const defaultSortInfo = { name: 'datum', dir: -1 }
 

// Konfiguriert die Filter für die Tabelle:
// - `id`: Filtert nach IDs größer oder gleich (`gte`), Typ: Zahl.
// - `bezeichnung`: Filtert nach Bezeichnung, die mit einem bestimmten Wert beginnt (`startsWith`), Typ: Zeichenkette.
// - `einstalldatum`: Filtert nach Installationsdatum, das nach oder am angegebenen Datum liegt (`afterOrOn`), Typ: Datum, absteigende Sortierung (`dir: -1`).
// - `ausstalldatum`: Filtert nach Ausstallungsdatum, das nach oder am angegebenen Datum liegt (`afterOrOn`), Typ: Datum, absteigende Sortierung (`dir: -1`).
// - `rassenBezeichnung`: Filtert nach Rassebezeichnung, die mit einem bestimmten Wert beginnt (`startsWith`), Typ: Zeichenkette.
// - `aktiv`: Filtert nach Aktivitätsstatus, der ungleich (`neq`) einem bestimmten Wert ist, Typ: Auswahl.


  const filterValue = [
    { name: "id", operator: "gte", type: "number" },
    { name: "bezeichnung", operator: "startsWith", type: "string", value: "" },
    {
      name: "einstalldatum",
      operator: "afterOrOn",
      type: "date",
      value: "",
      dir: -1
    },
    {
        name: "ausstalldatum",
        operator: "afterOrOn",
        type: "date",
        value: "",
        dir: -1
      },
    { name: "rassenBezeichnung", operator: "startsWith", type: "string", value: "" },
    { name: "aktiv", operator: "neq", type: "select", value: "" },

  ];


// Initialisiert den Zustand `selected` als leeres Objekt.
// Dieser Zustand wird verwendet, um ausgewählte Elemente zu verfolgen.


  const [selected, setSelected] = useState({});


// Erstellt eine benutzerdefinierte Übersetzungs-Konfiguration für die ReactDataGrid-Komponente,
// indem die Standard-i18n-Eigenschaften von ReactDataGrid überschrieben werden.
// Die neuen Texte werden für die Seitennummerierung, Anzeige- und Seiteneinstellungen definiert.


  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText:'Seite ',
    ofText:' von ',
    perPageText:'Ergebnisse pro Seite',
    showingText:'Zeigt '

  })
  

// Findet das ausgewählte "Herde"-Objekt aus der `folk`-Liste, basierend auf der `selectedFolkID`.
// Definiert eine CSS-Stilregel für das Grid, um eine Mindesthöhe von 550px festzulegen.


  const selectedHerde = folk.find(herde => herde.id.toString() === selectedFolkID);

  const gridStyle = { minHeight: 550 };


// Rendert die Übersicht der Historie für Herden innerhalb eines Containers. Die Struktur umfasst:
// - Einen Titel, der die Übersicht beschreibt.
// - Ein Card-Komponente, die die Daten für die Herden anzeigt.
// - Die ReactDataGrid-Komponente zeigt die Herden-Daten in einer Tabelle an, mit Unterstützung für Filter, Sortierung, und Paginierung.
//   Die Tabelle verwendet benutzerdefinierte Spalten, Filteroptionen, und bietet eine Möglichkeit zur Pagination.
//   Die Spracheinstellungen der Tabelle werden durch `i18n` konfiguriert.
//   Die Datenquelle für die Tabelle ist `folk`, und der aktuell ausgewählte Eintrag wird durch den `selected` Zustand bestimmt.
//   Das Grid hat eine Mindesthöhe von 550px.


  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">
                Übersicht Historie Herden
                </h1>
                <Card className="shadow">
                  <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                    <p className="m-0 fw-bold">Herden Daten</p>


                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <ReactDataGrid
                        idProperty="organisatorID"
                        defaultFilterValue={filterValue}
                        columns={columns}
                        groups={groups}
                        editable={false}
                        dataSource={folk}
                        style={gridStyle}
                        defaultLimit={10}
                        selected={selected}
                        
                        pagination
                        i18n={i18n}
                        defaultSortInfo={defaultSortInfo}
  
                      />
                    </Row>


                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default History;
