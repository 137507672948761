/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Profile.js
// Profil Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import { Store } from "react-notifications-component";


// Die `Operation`-Komponente verwaltet Daten für eine Operation, einschließlich der Länder. 
// Sie lädt die aktuellen Benutzerdaten aus dem lokalen Speicher und speichert sie in `currentUser`.
// Zwei State-Variablen werden verwendet: `operationData` für die Daten der Operation und `countries` für die Liste der Länder.
// Der `useEffect`-Hook wird verwendet, um beim Initialisieren der Komponente die Liste der Länder von der API zu laden und in `countries` zu speichern.


const Operation = () => {

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [operationData, setOperationData] = useState([]);
  const [countries, setCountries] = useState([]);
  const operationID = currentUser.betriebID;

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const res = await axios.get("/api/country");
        setCountries(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountries();
  }, []);


// Dieser `useEffect`-Hook lädt die Daten für die Operation, wenn die Komponente gemountet wird.
// Es wird überprüft, ob `currentUser` vorhanden ist. Falls ja, wird die `betriebID` des Benutzers verwendet,
// um die entsprechenden Daten von der API zu holen. Die abgerufenen Daten werden dann in `operationData` gespeichert.
// Der Hook hat keine Abhängigkeiten, daher wird er nur einmal beim Initialisieren der Komponente ausgeführt.


  useEffect(() => {
    const fetchtOperationData = async () => {
      try {
        let operationID;
        if (currentUser != null) {
          operationID = currentUser.betriebID;

          const res = await axios.get("/api/operation/", {
            params: { operationID, bust: Date.now() },
          });
          setOperationData(res.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchtOperationData();
  }, []);


// Der `useState`-Hook initialisiert den Zustand `inputs` mit den Werten aus `operationData`.
// Dies umfasst Details wie ID, Bezeichnung, Adresse, PLZ, Ort, Land-ID, Land und Betriebsnummer der Operation.
// Diese Zustandsvariable wird verwendet, um die Eingabefelder des Formulars zu steuern und deren Werte zu verwalten.


  const [inputs, setInputs] = useState({
    id: operationData.id,
    bezeichnung: operationData.bezeichnung,
    strasse: operationData.strasse,
    plz: operationData.plz,
    ort: operationData.ort,
    landID: operationData.landID,
    land: operationData.land,
    betriebsnr: operationData.betriebsnr,
  });


// Dieser `useEffect`-Hook aktualisiert den `inputs`-Zustand, wenn sich `operationData` ändert.
// Er sorgt dafür, dass die Eingabefelder des Formulars immer die aktuellen Daten der Operation anzeigen.
// Der Hook wird ausgeführt, sobald sich `operationData` ändert, um die Eingabefelder entsprechend zu aktualisieren.


  useEffect(() => {
    setInputs({
      id: operationData.id,
      bezeichnung: operationData.bezeichnung,
      strasse: operationData.strasse,
      plz: operationData.plz,
      ort: operationData.ort,
      landID: operationData.landID,
      land: operationData.land,
      betriebsnr: operationData.betriebsnr,
    });
  }, [operationData]);


// Die `handleChange`-Funktion aktualisiert den `inputs`-Zustand, wenn sich ein Eingabefeld ändert.
// Sie verwendet den Namen des Eingabefelds (`e.target.name`), um das entsprechende Feld im `inputs`-Zustand zu aktualisieren.
// Die Funktion sorgt dafür, dass der Zustand der Eingabefelder mit den Benutzereingaben synchron bleibt.


  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };


// `handleClickSave` ist eine asynchrone Funktion, die beim Klicken auf die Speichern-Schaltfläche aufgerufen wird.
// Sie verhindert das Standard-Formularverhalten, überprüft, ob alle erforderlichen Felder ausgefüllt sind,
// und zeigt eine Fehlermeldung an, wenn ein Feld fehlt.
// Wenn alle Felder ausgefüllt sind, wird eine PUT-Anfrage an den Server gesendet, um die Betriebs-Daten zu aktualisieren.
// Bei erfolgreicher Aktualisierung wird eine Erfolgsmeldung angezeigt, bei einem Fehler wird eine Fehlermeldung angezeigt.


  const handleClickSave = async (e) => {
    e.preventDefault();

  try {

    const form = document.querySelector(".needs-validation");
    form.classList.add("was-validated");

    const areAllFieldsFilled = (inputs.id !== "" && inputs.id !== null) &&
    (inputs.bezeichnung !== "" && inputs.bezeichnung !== null) &&
    (inputs.strasse !== "" && inputs.strasse !== null) &&
    (inputs.plz !== "" && inputs.plz !== null) &&
    (inputs.ort !== "" && inputs.ort !== null) &&
    (inputs.landID !== "" && inputs.landID !== null) &&
    (inputs.betriebsnr !== "" && inputs.betriebsnr !== null);
  
  


    if (!areAllFieldsFilled) {
    alert("Füllen Sie bitte die erforderlichen Daten aus.");
    return
    }

      await axios.put("/api/operation", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Betriebs-Daten wurden aktualisiert!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });

    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
      Store.addNotification({
          title: "Fehler",
          message: "Beim Hinzufügen der Betriebs-Daten ist ein Fehler aufgetreten.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
          duration: 6000,
          onScreen: true
          }
      });
    }
  };


// Diese Komponente rendert ein Formular zur Verwaltung von Betriebsdaten.
// Es zeigt ein Formular innerhalb einer `Card`, das die Betriebsdaten wie Bezeichnung, Adresse und Plz enthält.
// Das Formular bietet Eingabefelder für Betriebsnr, Bezeichnung, Adresse, Plz, Ort und Land.
// Das Land wird aus einer Liste von Ländern geladen, die durch eine `select`-Box ausgewählt werden kann.
// Die `handleClickSave`-Funktion wird beim Klick auf die "Änderungen speichern"-Schaltfläche aufgerufen,
// um die eingegebenen Daten zu speichern.


  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Administration Betrieb</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Betriebs-Daten</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  required
                                  type="text"
                                  name="betriebsnr"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={operationData.betriebsnr}
                                  onChange={handleChange}
                                />
                                <label>Betriebsnr</label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  required
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.bezeichnung}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">
                                  Bezeichnung
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  required
                                  type="text"
                                  name="strasse"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.strasse}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">Adresse</label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  required
                                  type="text"
                                  name="plz"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.plz}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">Plz</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  required
                                  type="text"
                                  name="ort"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={operationData.ort}
                                  onChange={handleChange}
                                />
                                <label>Ort</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  defaultValue={inputs.land}
                                  onChange={handleChange}
                                  name="landID"
                                >
                                  {operationData.land ? (
                                    <option hidden value="">
                                      {operationData.land}
                                    </option>
                                  ) : (
                                    <option hidden value="">
                                      -- Bitte ausw&auml;hlen --
                                    </option>
                                  )}

                                  {countries.map((country) => {
                                    return (
                                      <option
                                        key={country.ID}
                                        value={country.ID}
                                      >
                                        {country.Bezeichnung}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="floatingSelect">Land</label>
                              </div>
                            </Col>
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Operation;
