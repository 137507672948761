/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// NewStable.js
// NeuerStall Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

//
import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";


// `NewStable` ist eine Komponente, die Ställe für den aktuell eingeloggten Benutzer verwaltet.
// Die Komponente initialisiert mehrere Zustände:
// - `showButtons`: Kontrolliert, ob bestimmte Schaltflächen angezeigt werden.
// - `selectedStableId`: Speichert die ID des ausgewählten Stalls.
// - `availableDevices`: Enthält die Liste verfügbarer Geräte.
// - `selectedDevices`: Speichert die Liste der ausgewählten Geräte.
// - `chickenwatcher`: Verwaltet Daten über Chickenwatcher.
// - `stables`: Enthält die Liste der Ställe des aktuellen Benutzers.
//
// Die Funktion `fetchStable` holt die Ställe aus der Datenbank und speichert sie im Zustand `stables`.
// `useEffect` sorgt dafür, dass die Ställe geladen werden, sobald die Komponente gerendert wird.


const NewStable = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [showButtons, setShowButtons] = useState(false);
  const [selectedStableId, setSelectedStableId] = useState("");
  const [availableDevices, setAvailableDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [chickenwatcher, setChickenwatcher] = useState([]);
  const [stables, setStables] = useState([]);
  const fetchStable = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/stable/", {
        params: { operationID },
      });
      setStables(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchStable();
  }, []);


// `fetchChickenwatcher` ruft die Liste der Chickenwatcher für den aktuellen Betrieb ab.
// Die Funktion verwendet die `operationID` des aktuellen Benutzers und sendet eine GET-Anfrage
// an die API, um die Chickenwatcher-Daten zu laden. Diese werden anschließend im Zustand `chickenwatcher` gespeichert.
// Der `useEffect`-Hook stellt sicher, dass `fetchChickenwatcher` beim ersten Rendern der Komponente aufgerufen wird.


  const fetchChickenwatcher = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/chickenwatcher/", {
        params: { operationID },
      });
      setChickenwatcher(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchChickenwatcher();
  }, []);


// `selectedStable` speichert die ID des ausgewählten Stalls.
// `inputs` initialisiert ein Objekt mit leeren Feldern für die Stallbezeichnung und der `betriebID` des aktuellen Benutzers.
// `handleChange` aktualisiert die Werte in `inputs`, indem es das entsprechende Feld anhand des `name`-Attributs des Events (`e.target.name`) verändert.


  const [selectedStable, setSelectedStable] = useState("");

  const [inputs, setInputs] = useState({
    bezeichnung: "",
    betriebID: currentUser.betriebID,
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };


// `handleClickSave` wird beim Speichern der Stall-Daten ausgelöst. 
// Die Funktion verhindert den Standard-Formular-Submit, prüft ob alle erforderlichen Felder ausgefüllt sind, und zeigt eine Warnung, falls Daten fehlen. 
// Anschließend sendet sie eine POST-Anfrage an den Server, um die Stall-Daten zu speichern.
// Bei Erfolg wird eine Benachrichtigung über das erfolgreiche Speichern angezeigt und die Stallliste aktualisiert. 
// Bei einem Fehler wird eine Fehlermeldung angezeigt.


  const handleClickSave = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    try {
      const form = document.querySelector(".needs-validation");
      form.classList.add("was-validated");

      const areAllFieldsFilled =
        inputs.bezeichnung !== "" &&
        inputs.bezeichnung !== null &&
        inputs.stallnummer !== "" &&
        inputs.stallnummer !== null;

      if (!areAllFieldsFilled) {
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return;
      }

      await axios.post("/api/stable", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich hinzugefügt!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      fetchStable();
    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      Store.addNotification({
        title: "Fehler",
        message: "Beim Hinzufügen der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };


// `deleteStableHandler` wird aufgerufen, um eine Stallung zu löschen. 
// Es prüft zuerst, ob eine Stallung ausgewählt wurde. Wenn nicht, wird der Benutzer gewarnt. 
// Der Benutzer wird dann um Bestätigung gefragt. 
// Wenn bestätigt, wird eine DELETE-Anfrage gesendet, und die Stallung wird lokal aus dem `stables`-Array entfernt.
// Danach wird die Liste der Stallungen mit `fetchStable` aktualisiert. 
// Eine Erfolgsmeldung wird angezeigt, oder eine Fehlermeldung, wenn das Löschen fehlschlägt.


  const handleStableChangeForDelete = (e) => {
    setSelectedStableId(e.target.value);
  };

  const deleteStableHandler = async (e) => {
    e.preventDefault();
  
    if (!selectedStableId) {
      alert("Bitte wählen Sie eine Stallung aus, die gelöscht werden soll.");
      return;
    }
  
    const confirmation = window.confirm(
      "Sind Sie sicher, dass Sie diese Stallung löschen möchten?"
    );
  
    if (!confirmation) {
      console.log("Benutzer hat das Löschen abgebrochen");
      return;
    }
  
    try {
      await axios.put(`/api/stable/delete/${selectedStableId}`);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich gelöscht!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
  
      setStables(stables.filter((stable) => stable.id !== parseInt(selectedStableId)));
      setSelectedStableId("");
      fetchStable();
    } catch (err) {
      console.error("Fehler beim Löschen der Stallung: ", err);
      Store.addNotification({
        title: "Fehler",
        message: "Beim Löschen der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };

  const handleClickUpdate = async (e) => {
    e.preventDefault();

    try {
      const form = document.querySelector(".needs-validation-update");
      form.classList.add("was-validated");

      const areAllFieldsFilled =
        inputs.bezeichnung !== "" &&
        inputs.bezeichnung !== null &&
        inputs.stallnummer !== "" &&
        inputs.stallnummer !== null;

      if (!areAllFieldsFilled) {
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return;
      }

      const updateData = {
        stallInfo: inputs,
        devices: selectedDevices,
      };

      const response = await axios.put("/api/stable/update", updateData);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich aktualisiert!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      fetchStable();
      fetchChickenwatcher();
    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };


// Dieser `useEffect`-Hook wird ausgelöst, wenn sich `selectedStableId` oder `chickenwatcher` ändert.
// Wenn eine Stallung ausgewählt ist (`selectedStableId`), wird die Liste der `chickenwatcher` gefiltert:
// Die Geräte, die zur ausgewählten Stallung gehören, werden in `selectedDevices` gesetzt.
// Alle anderen Geräte, die nicht zur Stallung gehören, landen in `availableDevices`.
// Wenn keine Stallung ausgewählt ist, wird `availableDevices` auf die gesamte Liste von `chickenwatcher` gesetzt
// und `selectedDevices` wird geleert.


  useEffect(() => {
    if (selectedStableId) {
      const selected = chickenwatcher.filter(
        (cw) => cw.stallID.toString() === selectedStableId
      );
      const available = chickenwatcher.filter(
        (cw) => cw.stallID.toString() !== selectedStableId
      );
      setSelectedDevices(selected);
      setAvailableDevices(available);
    } else {
      setAvailableDevices(chickenwatcher);
      setSelectedDevices([]);
    }
  }, [selectedStableId, chickenwatcher]);


// Diese Funktion verschiebt ein Gerät von der Liste der verfügbaren Geräte (`availableDevices`)
// zur Liste der ausgewählten Geräte (`selectedDevices`).
// Sie sucht das Gerät mit der entsprechenden `deviceId` in `availableDevices`.
// Wenn das Gerät gefunden wird, wird es aus `availableDevices` entfernt und in `selectedDevices` hinzugefügt.
// Die Funktion `resetSelection` wird aufgerufen, um die Auswahl in der Liste der verfügbaren Geräte zurückzusetzen.



  const moveDeviceToSelected = (deviceId) => {
    const id = Number(deviceId);
    const device = availableDevices.find((d) => d.id === id);
    if (device) {
      setAvailableDevices((prevDevices) =>
        prevDevices.filter((d) => d.id !== id)
      );
      setSelectedDevices((prevDevices) => [...prevDevices, device]);

      resetSelection("availableDevices");
    }
  };


// Diese Funktion verschiebt ein Gerät von der Liste der ausgewählten Geräte (`selectedDevices`)
// zurück in die Liste der verfügbaren Geräte (`availableDevices`).
// Sie sucht das Gerät mit der entsprechenden `deviceId` in `selectedDevices`.
// Wenn das Gerät gefunden wird, wird es aus `selectedDevices` entfernt und zu `availableDevices` hinzugefügt.
// Die Funktion `resetSelection` wird aufgerufen, um die Auswahl in der Liste der ausgewählten Geräte zurückzusetzen.


  const moveDeviceToAvailable = (deviceId) => {
    const id = Number(deviceId);
    const device = selectedDevices.find((d) => d.id === id);
    if (device) {
      setSelectedDevices((prevDevices) =>
        prevDevices.filter((d) => d.id !== id)
      );
      setAvailableDevices((prevDevices) => [...prevDevices, device]);

      resetSelection("selectedDevices");
    }
  };


// Setzt die Auswahl eines HTML-Select-Elements zurück.
// Sucht das Element anhand seiner ID und setzt den `selectedIndex` auf -1,
// um alle Auswahlmöglichkeiten aufzuheben.


  const resetSelection = (elementId) => {
    const selectElement = document.getElementById(elementId);
    if (selectElement) {
      selectElement.selectedIndex = -1;
    }
  };


// Aktualisiert die ID der ausgewählten Stallung basierend auf der 
// Benutzerauswahl im Dropdown-Menü und speichert diese ID im Zustand.


  const handleStableChange = (e) => {
    setSelectedStableId(e.target.value);
  };


// Aktualisiert die Eingabefelder mit den Details der ausgewählten Stallung,
// wenn sich die ausgewählte Stall-ID oder die Liste der Stallungen ändert.


  useEffect(() => {
    const selectedStable = stables.find(
      (s) => s.id.toString() === selectedStableId
    );
    if (selectedStable) {
      setInputs({
        ...inputs,
        bezeichnung: selectedStable.bezeichnung,
        id: selectedStable.id,
      });
    }
  }, [selectedStableId, stables]);


// Bestimmt den Typ des Geräts basierend auf dem User-Agent-String des Browsers.
// Gibt "Android", "Tablet", "iOS" oder "Desktop" zurück.


  function checkDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Prüfen auf Android
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // Prüfen auf Tablet
    if (/iPad|Tablet|PlayBook/i.test(userAgent)) {
      return "Tablet";
    }
    // Prüfen auf iOS, dass kein iPad ist (iPhone, iPod, etc.)
    if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    // Default zu Desktop
    return "Desktop";
  }


// Bestimmt den Gerätetyp beim Laden der Komponente und setzt den Zustand `showButtons`,
// um Buttons nur bei Android, Tablet oder iOS anzuzeigen.


  useEffect(() => {
    const deviceType = checkDeviceType();
    setShowButtons(deviceType === "Android" || deviceType === "Tablet" || deviceType === "iOS");
  }, []);

  const deviceType = checkDeviceType();


// Diese Komponente bietet eine Benutzeroberfläche zur Verwaltung von Stallungen. 
// Sie umfasst folgende Funktionen:
//
// 1. **Hinzufügen einer neuen Stallung**:
//    - Ermöglicht das Hinzufügen einer neuen Stallung durch Eingabe eines Namens.
//    - Ein Button speichert die Änderungen.
//
// 2. **Bearbeiten einer bestehenden Stallung**:
//    - Ermöglicht die Auswahl einer bestehenden Stallung aus einem Dropdown-Menü.
//    - Zeigt die Details der ausgewählten Stallung an und erlaubt das Bearbeiten des Namens.
//    - Ermöglicht das Verschieben von Geräten zwischen "Verfügbare Geräte" und "Ausgewählte Geräte" durch Doppelklicks oder Buttons.
//
// 3. **Löschen einer Stallung**:
//    - Ermöglicht das Löschen einer Stallung durch Auswahl aus einem Dropdown-Menü und Bestätigung des Löschvorgangs.
//
// Die Komponente verwendet React Bootstrap für Layout und Styling und bietet umfassende Funktionen für die Verwaltung von Stallungen und Geräten.


  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Stammdaten Stallung</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Neue Stallung</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Name</label>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold">Stallung bearbeiten</p>
                        <form className="needs-validation-update">
                          <select
                            style={{ width: "auto" }}
                            onChange={handleStableChange}
                            value={selectedStableId}
                            className="form-control"
                            name="category"
                            required
                          >
                            <option value="">Bitte wählen</option>
                            {stables.map((stable) => (
                              <option key={stable.id} value={stable.id}>
                                {stable.bezeichnung}
                              </option>
                            ))}
                          </select>
                        </form>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation-updat">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  value={inputs.bezeichnung || ""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Name</label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            {" "}
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  multiple
                                  id="availableDevices"
                                  className="form-control"
                                  style={{ height: "150px" }}
                                  onDoubleClick={(e) =>
                                    moveDeviceToSelected(e.target.value)
                                  }
                                >
                                  {availableDevices.map((device) => (
                                    <option key={device.id} value={device.id}>
                                      {device.token} - {device.id}{" "}
                                      {"(" + device.stallBezeichnung + ")"}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="floatingInput">
                                  Verfügbare Geräte
                                </label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  multiple
                                  id="selectedDevices"
                                  className="form-control"
                                  style={{ height: "150px" }}
                                  onDoubleClick={(e) =>
                                    moveDeviceToAvailable(e.target.value)
                                  }
                                >
                                  {selectedDevices.map((device) => (
                                    <option key={device.id} value={device.id}>
                                      {device.token}
                                    </option>
                                  ))}
                                </select>
                                <label>Ausgewählte Geräte</label>
                              </div>
                            </Col>

                            {deviceType === "Desktop" && (
                              <React.Fragment>
                                <Col sm={6}>
                                  <b className="">
                                    Mit einem Doppelklick kann man die
                                    Chickenwatcher zuweisen.
                                  </b>
                                </Col>
                              </React.Fragment>
                            )}
                            {showButtons && (
                              <React.Fragment>
                                <Col sm={6}>
                                  <Button
                                    className="submitbutton"
                                    onClick={() => {
                                      const selectedValues = Array.from(
                                        document.getElementById(
                                          "availableDevices"
                                        ).selectedOptions
                                      ).map((option) => option.value);
                                      selectedValues.forEach(
                                        moveDeviceToSelected
                                      );
                                    }}
                                  >
                                    Zu ausgewählten hinzufügen
                                  </Button>
                                </Col>
                                <Col sm={6}>
                                  <Button
                                    className="submitbutton"
                                    onClick={() => {
                                      const selectedValues = Array.from(
                                        document.getElementById(
                                          "selectedDevices"
                                        ).selectedOptions
                                      ).map((option) => option.value);
                                      selectedValues.forEach(
                                        moveDeviceToAvailable
                                      );
                                    }}
                                  >
                                    Zu verfügbaren hinzufügen
                                  </Button>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>

                          <Button
                            onClick={handleClickUpdate}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Stallung löschen</p>
                      </Card.Header>
                      <Card.Body>
                        <form>
                          {/* Löschen */}
                          {/* Stallung löschen */}
                          <div className="form-floating mb-3">
                            <select
                              value={selectedStableId}
                              onChange={handleStableChangeForDelete}
                              className="form-control"
                              name="stableID"
                            >
                              <option hidden value="">
                                -- Bitte Stallung auswählen --
                              </option>
                              {stables.map((stable, index) => (
                                <option key={index} value={stable.id}>
                                  {stable.bezeichnung}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="floatingInput">
                              Stallung löschen:
                            </label>
                          </div>

                          <Button
                            onClick={deleteStableHandler}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default NewStable;
