/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// NewFlock.js
// NeueHerde Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";


// Diese Komponente verwaltet die Erstellung eines neuen Huhns (Flock).
// Sie führt die folgenden Hauptaktionen aus:
//
// 1. **Laden der Stallungen**:
//    - Beim Initialisieren der Komponente wird eine Anfrage an den Server gesendet, um die Stallungen für den aktuellen Benutzer abzurufen.
//    - Die erhaltenen Stallungen werden im Zustand `stables` gespeichert, um sie in der Benutzeroberfläche anzuzeigen.
//
// Die Komponente verwendet `useEffect` zum Abrufen der Stallungen und `axios` für die API-Anfrage.


const NewFlock = () => {

    const currentUser = JSON.parse(localStorage.getItem("user")); 
    const [stables, setStables] = useState([]);
    useEffect(() => {
      const fetchStable = async () => {
        try {
          const operationID = currentUser.betriebID;
          const res = await axios.get("/api/stable/", {
            params: { operationID },
          });
          setStables(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchStable();
    }, []);


// Diese Code-Schnipsel verwaltet den Zustand der Hühner-Rassen (`breeds`).
// Beim Laden der Komponente wird eine asynchrone Funktion ausgeführt, die die verfügbaren Hühner-Rassen vom Server abruft.
// Die erhaltenen Daten werden im Zustand `breeds` gespeichert, um sie später in der Benutzeroberfläche anzuzeigen.
//
// `useEffect` sorgt dafür, dass die Daten beim ersten Rendern der Komponente abgerufen werden, 
// und `axios` wird für die API-Anfrage verwendet.


    const [breeds, setBreeds] = useState([]);
    useEffect(() => {
        const fetchBreeds = async () => {
            try {
                const res = await axios.get("/api/breeds");
            setBreeds(res.data);
            } catch (err) {
            console.log(err);
            }
        };
        fetchBreeds();
    }, []);


// Verwaltet den Zustand der ausgewählten Hühner-Rasse (`selectedBreedId`) und ermöglicht die Aktualisierung dieses Zustands.
// Wenn der Benutzer eine Rasse auswählt, wird die ID der ausgewählten Rasse im Zustand `selectedBreedId` gespeichert.
// Zusätzlich wird der Zustand `inputs` aktualisiert, um die ausgewählte Rasse-ID (`rasseID`) zu enthalten.
//
// Die Variable `selectedBreedUsage` wird verwendet, um die Beschreibung (`verwendungbezeichnung`) der ausgewählten Rasse aus der `breeds`-Liste zu finden,
// basierend auf der ID der ausgewählten Rasse.


    const [selectedBreedId, setSelectedBreedId] = useState(null); 
    const handleBreedChange = (e) => {
        const selectedId = e.target.value;
        setSelectedBreedId(selectedId);
        setInputs((prev) => ({ ...prev, rasseID: selectedId }));
    };
    const selectedBreedUsage = breeds.find(breed => breed.id.toString() === selectedBreedId)?.verwendungbezeichnung;


// Initialisiert den Zustand `inputs` mit der Betriebs-ID des aktuellen Benutzers (`betriebID`).
// Die Funktion `handleChange` aktualisiert den Zustand `inputs`,
// indem sie den Wert des eingegebenen Feldes (`e.target.name`) auf den neuen Wert (`e.target.value`) setzt.
// Diese Funktion sorgt dafür, dass alle Änderungen in den Eingabefeldern im Zustand `inputs` reflektiert werden.


    const [inputs, setInputs] = useState({
        betriebID: currentUser.betriebID,
      });

    const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


// Verarbeitet das Speichern der Herden-Daten beim Klick auf den Speichern-Button.
// Verhindert das Standardverhalten des Formulars (`e.preventDefault()`).
// Fügt der Formular-Klasse `needs-validation` die Klasse `was-validated` hinzu,
// um die Bootstrap-Validierung anzuzeigen.
// Überprüft, ob alle erforderlichen Felder ausgefüllt sind, und zeigt eine Fehlermeldung an,
// wenn mindestens ein Feld leer oder null ist.
// Falls alle Felder korrekt ausgefüllt sind, wird eine POST-Anfrage an den Server gesendet,
// um die Herden-Daten zu speichern.
// Bei Erfolg wird eine Erfolgsmeldung angezeigt. Bei Fehlern wird eine Fehlermeldung angezeigt
// und in der Konsole protokolliert.


    const handleClickSave = async (e) => {
        e.preventDefault();
    
      try {
    
        const form = document.querySelector(".needs-validation");
        form.classList.add("was-validated");
    
        const areAllFieldsFilled = 
        (inputs.ausstallung !== "" && inputs.ausstallung !== null) &&
        (inputs.bezeichnung !== "" && inputs.bezeichung !== null) &&
        (inputs.einstallung !== "" && inputs.einstallung !== null) &&
        (inputs.rasseID !== "" && inputs.rasseID !== null);
      
      
    
    
        if (!areAllFieldsFilled) {
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return
        }
    
          await axios.post("/api/folk", inputs);
          Store.addNotification({
            title: "Erfolgreich",
            message: "Die Herden-Daten wurden erfolgreich gespeichert!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 6000,
              onScreen: true,
            },
          });
    
        } catch (err) {
          console.error("Fehler beim Speichern der Herden-Daten: ", err);
          Store.addNotification({
              title: "Fehler",
              message: "Beim Hinzufügen der Betriebs-Daten ist ein Fehler aufgetreten.",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
              duration: 6000,
              onScreen: true
              }
          });
        }
      };


// Rendern des Formulars für die Eingabe von Herden-Stammdaten.
// Das Formular besteht aus mehreren Abschnitten:
// 1. **Neue Herde**: 
//    - **Herden-Bezeichnung**: Eingabefeld für die Bezeichnung der Herde.
//    - **Einstallung**: Eingabefeld für das Datum der Einstallung.
// 2. **Rasse**: Dropdown-Auswahl zur Auswahl der Rasse, basierend auf der `breeds`-Liste.
// 3. **Ausstallung**: Eingabefeld für das Datum der Ausstallung.
// 4. **Nutzart**: Eingabefeld, das den Nutztyp basierend auf der ausgewählten Rasse anzeigt, nur lesbar.
// 5. **Anzahl**: Eingabefeld für die Anzahl der Tiere, nur positive Ganzzahlen erlaubt.
// Am Ende des Formulars gibt es einen Button zum Speichern der Änderungen, der die `handleClickSave`-Funktion auslöst.


  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Stammdaten Herde</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Neue Herde</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Herden-Bezeichnung
                                </label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                              <input
                                    type="date"
                                    className="form-control"
                                    id="floatingInput"
                                    name="einstallung"
                                    defaultValue=""
                                    onChange={handleChange}
                                    required
                                />
                                <label>Einstallung</label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            <Col sm={6}>
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-control"
                                        defaultValue={""}
                                        onChange={handleBreedChange}
                                        name="rasseID"
                                    >



                                        <option hidden value="">
                                            -- Bitte ausw&auml;hlen --
                                        </option>
    

                                        {breeds.map((breed) => {
                                        return (
                                            <option
                                            key={breed.id}
                                            value={breed.id}
                                            >
                                            {breed.bezeichnung}
                                            </option>
                                        );
                                        })}
                                    </select>
                                    <label htmlFor="floatingSelect">Rasse</label>
                                </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                              <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInput"
                                  name="ausstallung"
                                  defaultValue=""
                                  onChange={handleChange}
                                  required
                                />
                                <label>Ausstallung</label>
                              </div>
                            </Col>
                          </Row>
                          
                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="nutzart"
                                  className="form-control"
                                  id="floatingInput"
                                  value={selectedBreedUsage || ""}
                                  readOnly
                                />
                                <label htmlFor="floatingInput">Nutzart</label>
                              </div>
                              
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="number"
                                  name="anzahl"
                                  className="form-control"
                                  id="floatingInput"
                                  value={""}
                                  onChange={handleChange}
                                  min="0"
                                  step="1"
                                  required
                                />
                                <label htmlFor="floatingInput">Anzahl</label>
                              </div>
                            </Col>
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default NewFlock;
