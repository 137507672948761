/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Overview.js
// Übersicht page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 28.10.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import Chart from "chart.js";
import PieChartComponent from "../components/PieChartComponent";
import LineChartComponent from '../components/LineChartComponent';
import StatisticsOverview from "../components/StatisticsOverview";


// Die Overview-Komponente verwaltet eine Reihe von Daten, einschließlich Benutzerinformationen (currentUser), 
// operationbezogene Daten (operationData), Daten zu Herden (folk, selectedFolkId) 
// und verschiedene Arten von Statistiken (statisticsData, todayStatisticsData, allDayStatisticsData).

// Der Einsatz von useState initialisiert die Statusvariablen, und die entsprechenden Setter-Funktionen 
// (setOperationData, setFolk usw.) ermöglichen später das Aktualisieren des Status.


const Overview = (info) => {
  let [currentUser, setCurrentUser] = useState(null);

  currentUser = info.info;

  const [operationData, setOperationData] = useState([]);

  const [selectedFolkId, setSelectedFolkId] = useState("");

  const [folk, setFolk] = useState([]);

  const [statisticsData, setStatisticsData] = useState([]);
  const [todayStatisticsData, setTodayStatisticsData] = useState([]);
  const [allDayStatisticsData, setAllDayStatisticsData] = useState([]);
  const [netbeeData, setNetbeeData] = useState([]); // New state for NetBEE data

  
// Dieser useEffect wird einmal nach dem Mounten der Komponente ausgeführt. Er definiert und ruft sofort fetchtOperationData auf,
// welches überprüft, ob currentUser existiert, und dann die Betriebsdaten mit currentUser.betriebID abruft.
// Die API-Anfrage enthält einen "bust"-Zeitstempel, um Caching zu vermeiden. Die abgerufenen Daten werden im
// operationData-Status gespeichert, und etwaige Fehler werden erfasst und protokolliert.
  
  
  useEffect(() => {
    const fetchtOperationData = async () => {
      try {
        let operationID;
        if (currentUser != null) {
          operationID = currentUser.betriebID;

          const res = await axios.get("/api/operation/", {
            params: { operationID, bust: Date.now() },
          });

          setOperationData(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchtOperationData();
  }, []);


// Dieser useEffect wird einmal nach dem Mounten der Komponente ausgeführt. Er definiert und ruft sofort fetchFolk auf,
// welches überprüft, ob currentUser existiert, und dann die Volksdaten mit currentUser.betriebID abruft.
// Die API-Anfrage ruft Daten von "/api/folk" ab und aktualisiert den folk-Status. Wenn Daten zurückgegeben werden,
// wird die ID des ersten Volks als ausgewählt gesetzt. Fehler werden erfasst und protokolliert.
// Die Variablen lineChartLabels und lineChartData werden aus allDayStatisticsData abgeleitet und für ein Diagramm verwendet.
// handleFolkChange aktualisiert die selectedFolkId basierend auf der Benutzereingabe.


  useEffect(() => {
    const fetchFolk = async () => {
      try {
        if (currentUser != null) {
          const operationID = currentUser.betriebID;
          const res = await axios.get("/api/folk", {
            params: { operationID },
          });
          setFolk(res.data);

          if (res.data.length > 0) {
            setSelectedFolkId(res.data[0].id.toString());
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, []);

  const lineChartLabels = allDayStatisticsData.map(row => row.tag);
  const lineChartData = allDayStatisticsData.map(row => row.durchschnitt);

  const handleFolkChange = (e) => {
    setSelectedFolkId(e.target.value);
  };


// Dieser useEffect wird immer dann ausgeführt, wenn sich selectedFolkId ändert. Er definiert und ruft sofort fetchStatisticsData auf,
// welches überprüft, ob selectedFolkId gesetzt ist. Dann werden die Statistikdaten mit der selectedFolkId abgerufen
// und ein Zeitstempel hinzugefügt, um Caching zu vermeiden. Die abgerufenen Daten werden im statisticsData-Status gespeichert.
// Fehler werden erfasst und protokolliert.


  useEffect(() => {
    const fetchStatisticsData = async () => {
      if (selectedFolkId) {
        try {
          const res = await axios.get("/api/statistics/", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchStatisticsData();
  }, [selectedFolkId]);


// Dieser useEffect wird immer dann ausgeführt, wenn sich selectedFolkId ändert. Er definiert und ruft sofort fetchTodayStatisticsData auf,
// welches überprüft, ob selectedFolkId gesetzt ist. Dann werden die Statistikdaten von heute mit der selectedFolkId abgerufen
// und ein Zeitstempel hinzugefügt, um Caching zu vermeiden. Die abgerufenen Daten werden im todayStatisticsData-Status gespeichert.
// Fehler werden erfasst und protokolliert.


  useEffect(() => {
    const fetchTodayStatisticsData = async () => {
      if (selectedFolkId) {
        try {
          const res = await axios.get("/api/statistics/today-statistics", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setTodayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchTodayStatisticsData();
  }, [selectedFolkId]);

  ///TODO statistics/selectStatsTillCurrentDate/ 
  //In Verwendung außerhalb von Showcases sollte '/allday-statistics' verwendet werden, da statistics/selectStatsTillCurrentDate/ nur die Daten bis zum aktuellen Datum zurück gibt
  useEffect(() => {
    const fetchAllDayStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/selectStatsTillCurrentDate/", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setAllDayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchAllDayStatisticsData();
  }, [selectedFolkId]);


  useEffect(() => {
    const fetchLatestNetbeeData = async () => {
        try {
            const response = await axios.get('/api/netbee/latest');
            setNetbeeData(response.data ? [response.data] : []);
        } catch (error) {
            console.error('Error fetching NetBEE data:', error.message);
        }
    };

    fetchLatestNetbeeData();
}, []);


// Diese Zeile des Codes sucht das Objekt im `folk`-Array, bei dem die `id` mit der `selectedFolkId` übereinstimmt.
// Die `id` wird in einen String konvertiert, um sicherzustellen, dass der Vergleich konsistent mit `selectedFolkId` ist, die ein String ist.


  const selectedHerde = folk.find(
    (herde) => herde.id.toString() === selectedFolkId
  );


// Diese Zeile des Codes bestimmt das `chartData` basierend auf dem Inhalt von `statisticsData`.
// Wenn `statisticsData` Einträge enthält, wird `chartData` auf ein Array gesetzt, das die Werte `herdeistalter`
// und `altersdifferenz` des ersten Eintrags enthält. Wenn `statisticsData` leer ist, wird `chartData` auf
// `[0, 0]` als Standardwert gesetzt.


  const chartData =
    statisticsData.length > 0
      ? [statisticsData[0].herdeistalter, statisticsData[0].altersdifferenz]
      : [0, 0];


// Diese Zeile des Codes setzt das Array `statusChartData` basierend darauf, ob `selectedHerde` definiert ist.
// Wenn `selectedHerde` existiert, wird `statusChartData` auf ein Array gesetzt, das `positiveDaten` und `negativDaten`
// von `selectedHerde` enthält, wobei auf `0` zurückgegriffen wird, wenn ein Wert nicht vorhanden ist. Wenn `selectedHerde` nicht definiert ist,
// wird `statusChartData` auf `[0, 0]` gesetzt.


  const statusChartData = selectedHerde
    ? [selectedHerde.positiveDaten || 0, selectedHerde.negativDaten || 0]
    : [0, 0];

  return (


// Der folgende JSX-Code erstellt das Layout für das Dashboard:
// - <Container fluid> und <div className="main"> strukturieren die Seite, um den gesamten Inhalt zu umschließen.
// - <Row> und <Col> von Bootstrap sorgen für ein responsives Layout in Zeilen und Spalten.
// - Innerhalb der Karte <Card> wird ein Diagramm angezeigt, dessen Daten basierend auf der Auswahl im Dropdown-Menü (<select>) aktualisiert werden.
// - Die Karte zeigt Details zur ausgewählten Herde und zwei Diagramme (PieChartComponent) zur Altersverteilung und zum Status der Daten.
// - Eine weitere Karte zeigt eine Übersicht der Statistiken und eine Zeitreihe des Durchschnittsgewichts mittels <LineChartComponent>.


<Container fluid>
  <div className="main">
    <Row>
      <Col>
        <div className="contentbox">
          <h1 className="title">Dashboard</h1>
          <Row className="mb-3">
            <Col>
              <Card className="shadow mb-3">
                <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                  <p className="m-0 fw-bold">Herde Diagramm</p>
                  <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkId}
                    onChange={handleFolkChange}
                    required
                  >
                    {folk.map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.bezeichnung}
                      </option>
                    ))}
                  </select>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xs={12} md={6} className="d-flex flex-column">
                      {selectedHerde ? (
                        <>
                          <div className="mb-3">
                            <b>Einstalldatum: </b> {selectedHerde.einstallDatum} <br />
                            <b>Ausstalldatum: </b> {selectedHerde.ausstallDatum} <br />
                            <b>Rasse: </b> {selectedHerde.rassenBezeichnung} <br />
                            <b>Anzahl: </b> {selectedHerde.anzahl} <br />
                            <b>Stall: </b> {selectedHerde.stallBezeichnung} <br />
                          </div>
                          <hr className="mb-3" />
                          <div className="mt-n3">
                            <b>Befund:</b>
                            <div className="mb-2">
                              <b>
                                {selectedHerde.positiveDaten} positiv von{" "}
                                {selectedHerde.negativDaten}
                              </b>{" "}
                            </div>
                            <hr className="mb-3" />
                            {todayStatisticsData && todayStatisticsData.length > 0 ? (
                              <div>
                                <b>
                                  Heute wurden{" "}
                                  <span style={{ color: "red" }}>
                                    {todayStatisticsData[0].anzahl}
                                  </span>{" "}
                                  Datensätze aufgenommen und das Durchschnittsgewicht beträgt{" "}
                                  <span style={{ color: "red", marginBottom: '50px', display: 'inline-block' }}>
                                    {todayStatisticsData[0].durchschnitt
                                      ?.toFixed(2)
                                      .replace(".", ",") || "0,00"}g
                                  </span>.
                                </b>
                              </div>
                            ) : (
                              <div>Keine Daten für heute verfügbar.</div>
                            )}
                            <div className="mt-3">
                                <h5>Aktuelle Umgebungsdaten</h5>
                                {netbeeData && netbeeData.length > 0 ? (
                                  <div style={{ minHeight: '50px' }}>
                                    <div style={{ marginBottom: '8px' }}>
                                      <strong>Datum und Zeit:</strong> {new Date(netbeeData[0].timestamp).toLocaleString('de-DE', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: false,
                                      })}
                                   </div>
                                  <div style={{ marginBottom: '8px' }}>
                                      <strong>Temperatur:</strong> {netbeeData[0].temperature}°C
                                  </div>
                                  <div style={{ marginBottom: '8px' }}>
                                      <strong>Luftdruck:</strong> {netbeeData[0].airPressure} hPa
                                  </div>
                                  <div style={{ marginBottom: '8px' }}>
                                      <strong>Luftfeuchtigkeit:</strong> {netbeeData[0].humidity}%
                                  </div>
                                </div>
                            ) : (
                                <div>Lade Daten...</div>
                              )}
                          </div>
                          </div>
                        </>
                      ) : (
                        <div>Herde auswählen</div>
                      )}
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column align-items-center">
                      <div className="chart-container mb-3" style={{ width: '100%', maxWidth: '400px' }}>
                        {selectedHerde ? (
                          <PieChartComponent
                            data={chartData}
                            title="Altersverteilung"
                            style={{ width: "100%", height: "180px" }}
                          />
                        ) : (
                          <div>Herde auswählen</div>
                        )}
                      </div>
                      <div className="chart-container" style={{ width: '100%', maxWidth: '400px' }}>
                        {selectedHerde ? (
                          <PieChartComponent
                            data={statusChartData}
                            title="Status der Daten"
                            style={{ width: "100%", height: "180px" }}
                          />
                        ) : (
                          <div>Herde auswählen</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Card className="shadow mb-3">
                <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                  <p className="m-0 fw-bold">Statistiken Übersicht</p>

                  <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkId}
                    onChange={handleFolkChange}
                    required
                  >
                    {folk.map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.bezeichnung}
                      </option>
                    ))}
                  </select>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                      <div style={{ width: '100%', maxWidth: '100%', padding: '20px' }}>
                        <StatisticsOverview data={allDayStatisticsData} />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card className="shadow mb-3">
                <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                  <p className="m-0 fw-bold">Durchschnittsgewicht im Zeitverlauf</p>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                      <div style={{ width: '100%', padding: '20px', minWidth: '400px', minHeight: '400px' }}>
                        <LineChartComponent
                          data={lineChartData}
                          labels={lineChartLabels}
                          title="Durchschnittsgewicht im Zeitverlauf"
                          style={{
                            width: '100%',
                            height: '300px',
                            maxWidth: '100%',
                            minHeight: '300px'
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </div>
</Container>
);
};

export default Overview;
